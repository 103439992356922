var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "title",
    staticStyle: {
      "display": "flex"
    }
  }, [_vm._v(" Teilnehmende "), _c('v-spacer'), _c('v-btn', {
    attrs: {
      "x-small": "",
      "outlined": "",
      "text": ""
    },
    on: {
      "click": _vm.openAddUserDialog
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")])], 1), _vm._l(_vm.attendees, function (attendee, index) {
    return _c('v-list-item', {
      key: "users".concat(index)
    }, [_c('v-list-item-avatar', [_c('v-avatar', {
      staticClass: "white--text",
      attrs: {
        "color": "primary"
      }
    }, [attendee.firstName ? _c('span', [_vm._v(" " + _vm._s(attendee.firstName[0]) + " ")]) : _vm._e(), attendee.lastName ? _c('span', [_vm._v(" " + _vm._s(attendee.lastName[0]) + " ")]) : _vm._e()])], 1), _c('v-list-item-content', [_c('v-list-item-title', [_vm._v(" " + _vm._s(attendee.firstName) + " " + _vm._s(attendee.lastName) + " ")]), _c('v-list-item-subtitle', {
      staticClass: "text--primary"
    }, [_vm._v(" " + _vm._s(attendee.email) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(attendee.phone))])], 1), _c('v-list-item-actions', [_c('context-menu', [_c('v-list-item', {
      on: {
        "click": function click($event) {
          return _vm.openEditUserDialog(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("edit")))]), _c('v-list-item', {
      on: {
        "click": function click($event) {
          return _vm.openRemoveUserDialog(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1)], 1)], 1);
  }), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUserEditDialogActive,
      "title": _vm.$t('edit'),
      "fullscreen": _vm.$vuetify.breakpoint.mobile,
      "rightLoading": _vm.isFormLoading,
      "rightDisabled": !_vm.isFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUserEditDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUserEditDialogActive = $event;
      },
      "leftClick": _vm.abortEditUserDialog,
      "rightClick": _vm.confirmEditUserDialog
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": 0
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.editUser.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "firstName", $$v);
      },
      expression: "editUser.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.editUser.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "lastName", $$v);
      },
      expression: "editUser.lastName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.email'),
      "rules": _vm.emailRule,
      "outlined": ""
    },
    model: {
      value: _vm.editUser.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "email", $$v);
      },
      expression: "editUser.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.phone'),
      "rules": _vm.phoneRuleOptional,
      "outlined": ""
    },
    model: {
      value: _vm.editUser.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "phone", $$v);
      },
      expression: "editUser.phone"
    }
  })], 1)], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUserAddDialogActive,
      "title": _vm.$t('add'),
      "rightText": _vm.$t('add'),
      "fullscreen": _vm.$vuetify.breakpoint.mobile,
      "rightLoading": _vm.isFormLoading,
      "rightDisabled": !_vm.isFormValid
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUserAddDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUserAddDialogActive = $event;
      },
      "leftClick": _vm.abortAddUserDialog,
      "rightClick": _vm.confirmAddUserDialog
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isFormValid,
      callback: function callback($$v) {
        _vm.isFormValid = $$v;
      },
      expression: "isFormValid"
    }
  }, [_c('v-card', {
    attrs: {
      "elevation": 0
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.firstName'),
      "outlined": ""
    },
    model: {
      value: _vm.editUser.firstName,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "firstName", $$v);
      },
      expression: "editUser.firstName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.lastName'),
      "outlined": ""
    },
    model: {
      value: _vm.editUser.lastName,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "lastName", $$v);
      },
      expression: "editUser.lastName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.email'),
      "rules": _vm.emailRule,
      "outlined": ""
    },
    model: {
      value: _vm.editUser.email,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "email", $$v);
      },
      expression: "editUser.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('objects.contactAndName.phone'),
      "rules": _vm.phoneRuleOptional,
      "outlined": ""
    },
    model: {
      value: _vm.editUser.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.editUser, "phone", $$v);
      },
      expression: "editUser.phone"
    }
  })], 1)], 1)], 1), _c('confirm-action-dialog', {
    attrs: {
      "isDialogActive": _vm.isUserRemoveDialogActive,
      "title": _vm.$t('remove'),
      "rightText": _vm.$t('remove'),
      "fullscreen": _vm.$vuetify.breakpoint.mobile,
      "rightLoading": _vm.isFormLoading,
      "rightColor": "error"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isUserRemoveDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isUserRemoveDialogActive = $event;
      },
      "leftClick": _vm.abortRemoveUserDialog,
      "rightClick": _vm.confirmRemoveUserDialog
    }
  }, [_vm.attendees[_vm.removeUserIndex] ? _c('v-list-item', [_c('v-list-item-avatar', [_c('v-avatar', {
    staticClass: "white--text",
    attrs: {
      "color": "primary"
    }
  }, [_vm.attendees[_vm.removeUserIndex].firstName ? _c('span', [_vm._v(" " + _vm._s(_vm.attendees[_vm.removeUserIndex].firstName[0]) + " ")]) : _vm._e(), _vm.attendees[_vm.removeUserIndex].lastName ? _c('span', [_vm._v(" " + _vm._s(_vm.attendees[_vm.removeUserIndex].lastName[0]) + " ")]) : _vm._e()])], 1), _c('v-list-item-content', [_vm.attendees[_vm.removeUserIndex].firstName ? _c('v-list-item-title', [_vm._v(" " + _vm._s(_vm.attendees[_vm.removeUserIndex].firstName) + " " + _vm._s(_vm.attendees[_vm.removeUserIndex].lastName) + " ")]) : _vm._e(), _c('v-list-item-subtitle', {
    staticClass: "text--primary"
  }, [_vm._v(" " + _vm._s(_vm.attendees[_vm.removeUserIndex].email) + " ")]), _c('v-list-item-subtitle', [_vm._v(" " + _vm._s(_vm.attendees[_vm.removeUserIndex].phone))])], 1)], 1) : _vm._e()], 1)], 2);
}
var staticRenderFns = []

export { render, staticRenderFns }