var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('Can', {
    attrs: {
      "I": _vm.ActionEnum.CREATE,
      "a": _vm.ResourceEnum.HANDOVER
    }
  }, [_vm._t("activator", null, {
    "on": _vm.open
  })], 2), _vm.handover ? _c('confirm-action-dialog', {
    attrs: {
      "title": _vm.$t('inspection.create.title'),
      "isDialogActive": _vm.isDialogActive,
      "rightLoading": _vm.isCreateLoading,
      "rightDisabled": !_vm.isValid,
      "width": "1050"
    },
    on: {
      "update:isDialogActive": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "update:is-dialog-active": function updateIsDialogActive($event) {
        _vm.isDialogActive = $event;
      },
      "rightClick": _vm.create,
      "leftClick": _vm.close,
      "close": _vm.close
    }
  }, [_c('v-form', {
    model: {
      value: _vm.isInspectionValid,
      callback: function callback($$v) {
        _vm.isInspectionValid = $$v;
      },
      expression: "isInspectionValid"
    }
  }, [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "7"
    }
  }, [_c('div', {
    staticClass: "title mb-4"
  }, [_vm._v(_vm._s(_vm.$t("inspection.create.general")))]), _c('v-text-field', {
    ref: "titleTextField",
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.inspection.title')
    },
    model: {
      value: _vm.inspection.title,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "title", $$v);
      },
      expression: "inspection.title"
    }
  }), _c('v-textarea', {
    attrs: {
      "rules": _vm.requiredRule,
      "outlined": "",
      "label": _vm.$t('objects.inspection.description')
    },
    model: {
      value: _vm.inspection.description,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "description", $$v);
      },
      expression: "inspection.description"
    }
  }), _c('custom-field-date-time', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.inspection.start')
      }
    },
    model: {
      value: _vm.inspection.startDate,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "startDate", $$v);
      },
      expression: "inspection.startDate"
    }
  }), _c('custom-field-date-time', {
    attrs: {
      "customField": {
        label: _vm.$t('objects.inspection.end')
      }
    },
    model: {
      value: _vm.inspection.endDate,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "endDate", $$v);
      },
      expression: "inspection.endDate"
    }
  }), _c('address-form', {
    attrs: {
      "isValid": _vm.isAddressValid,
      "subheader": _vm.$t('objects.inspection.address')
    },
    on: {
      "update:isValid": function updateIsValid($event) {
        _vm.isAddressValid = $event;
      },
      "update:is-valid": function updateIsValid($event) {
        _vm.isAddressValid = $event;
      }
    },
    model: {
      value: _vm.inspection.address,
      callback: function callback($$v) {
        _vm.$set(_vm.inspection, "address", $$v);
      },
      expression: "inspection.address"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "md": "5"
    }
  }, [_vm._l(_vm.inspection.attendees, function (attendee, index) {
    return _c('div', {
      key: index,
      staticClass: "blueprint-form my-4"
    }, [_c('v-row', {
      staticClass: "mx-1 mb-1",
      attrs: {
        "align": "center",
        "justify": "space-between"
      }
    }, [_c('span', {
      staticClass: "title mb-2"
    }, [_vm._v("Teilnehmer*in " + _vm._s(index + 1))]), _c('v-btn', {
      attrs: {
        "outlined": "",
        "color": "error",
        "small": ""
      },
      on: {
        "click": function click($event) {
          return _vm.removeItem(index);
        }
      }
    }, [_vm._v(_vm._s(_vm.$t("remove")))])], 1), _c('v-text-field', {
      attrs: {
        "rules": _vm.requiredRule,
        "outlined": "",
        "label": _vm.$t('companyName')
      },
      model: {
        value: attendee.companyName,
        callback: function callback($$v) {
          _vm.$set(attendee, "companyName", $$v);
        },
        expression: "attendee.companyName"
      }
    }), _c('v-text-field', {
      attrs: {
        "rules": _vm.requiredRule,
        "outlined": "",
        "label": _vm.$t('firstName')
      },
      model: {
        value: attendee.firstName,
        callback: function callback($$v) {
          _vm.$set(attendee, "firstName", $$v);
        },
        expression: "attendee.firstName"
      }
    }), _c('v-text-field', {
      attrs: {
        "rules": _vm.requiredRule,
        "outlined": "",
        "label": _vm.$t('lastName')
      },
      model: {
        value: attendee.lastName,
        callback: function callback($$v) {
          _vm.$set(attendee, "lastName", $$v);
        },
        expression: "attendee.lastName"
      }
    }), _c('v-text-field', {
      attrs: {
        "rules": _vm.emailRule,
        "outlined": "",
        "label": _vm.$t('mail')
      },
      model: {
        value: attendee.email,
        callback: function callback($$v) {
          _vm.$set(attendee, "email", $$v);
        },
        expression: "attendee.email"
      }
    }), _c('v-text-field', {
      attrs: {
        "rules": _vm.phoneRule,
        "outlined": "",
        "label": _vm.$t('phone')
      },
      model: {
        value: attendee.phone,
        callback: function callback($$v) {
          _vm.$set(attendee, "phone", $$v);
        },
        expression: "attendee.phone"
      }
    })], 1);
  }), _c('v-btn', {
    attrs: {
      "outlined": "",
      "color": "success"
    },
    on: {
      "click": _vm.addUser
    }
  }, [_vm._v("Teilnehmende hinzüfugen")])], 2)], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }