var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('paginated-table', {
    attrs: {
      "headers": _vm.headers,
      "store": _vm.handoverReportStore,
      "baseQuery": {
        partnerId: _vm.partnerId
      },
      "predefinedFilter": _vm.predefinedFilter
    },
    on: {
      "click:row": _vm.openReportSideCard
    },
    scopedSlots: _vm._u([{
      key: "item.title",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_vm.isLoadingReport ? _c('v-skeleton-loader', {
          attrs: {
            "type": "list-item"
          }
        }) : _c('a', {
          on: {
            "click": function click() {
              return _vm.openReportSideCard(item);
            }
          }
        }, [_vm._v(" " + _vm._s(item.title) + " ")])];
      }
    }])
  }), _vm.selectedReport ? _c('partner-report-detail-side-card', {
    attrs: {
      "loading": _vm.isLoadingReport,
      "report": _vm.selectedReport
    },
    on: {
      "close": _vm.closeReportSideCard
    }
  }) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }