var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-form', {
    model: {
      value: _vm.localIsValid,
      callback: function callback($$v) {
        _vm.localIsValid = $$v;
      },
      expression: "localIsValid"
    }
  }, [_c('v-expansion-panels', {
    staticClass: "my-4",
    attrs: {
      "multiple": "",
      "outlined": ""
    },
    model: {
      value: _vm.panel,
      callback: function callback($$v) {
        _vm.panel = $$v;
      },
      expression: "panel"
    }
  }, [_c('v-expansion-panel', [_c('v-expansion-panel-header', {
    attrs: {
      "disable-icon-rotate": ""
    },
    scopedSlots: _vm._u([{
      key: "actions",
      fn: function fn() {
        return [!_vm.localIsValid ? _c('v-icon', {
          attrs: {
            "color": "error"
          }
        }, [_vm._v(" mdi-alert-circle ")]) : _vm.value.companyName !== '' ? _c('v-icon', {
          attrs: {
            "color": "teal"
          }
        }, [_vm._v(" mdi-check ")]) : _vm._e(), _vm.panel.length ? _c('v-icon', [_vm._v("mdi-chevron-down")]) : _c('v-icon', [_vm._v("mdi-chevron-right")])];
      },
      proxy: true
    }])
  }, [_vm._v(" " + _vm._s(_vm.subheader) + " "), _vm.value.companyName ? _c('b', {
    staticClass: "ml-2"
  }, [_vm._v("(" + _vm._s(_vm.value.companyName) + ")")]) : _vm._e(), _c('v-spacer')], 1), _c('v-expansion-panel-content', [_c('v-text-field', {
    attrs: {
      "label": _vm.$t('company'),
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value.companyName,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "companyName", $$v);
      },
      expression: "value.companyName"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('email'),
      "rules": _vm.isEmailRule,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value.email,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "email", $$v);
      },
      expression: "value.email"
    }
  }), _c('v-text-field', {
    attrs: {
      "label": _vm.$t('phone'),
      "rules": _vm.isPhoneRule,
      "outlined": "",
      "dense": ""
    },
    model: {
      value: _vm.value.phone,
      callback: function callback($$v) {
        _vm.$set(_vm.value, "phone", $$v);
      },
      expression: "value.phone"
    }
  })], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }